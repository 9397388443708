import React from 'react'

import alltricks from './images/alltricks.png'
import amazon from './images/amazon.avif'
import decathlon from './images/decathlon.png'
import ekosport from './images/ekosport.png'
import hardloop from './images/hardloop.webp'
import irun from './images/irun.avif'
import lecyclo from './images/lecyclo.webp'

import styles from './app.module.css';

export default function App({ product }) {
	return <div className={styles.prices}>
		<h4 className={styles.title}>
			{ Object.keys(product.providers).length > 1 ? "Comparer les prix" : "Acheter en ligne" }
		</h4>
		{Object.keys(product.providers)
			.sort((a, b) => {
				return product.providers[a].currentPrice - product.providers[b].currentPrice
			})
			.slice(0, 3)
			.map((provider, index) => {
				const metadata = product.providers[provider]

				return (
					<a href={metadata.affiliateLink} className={styles.price} target="_blank" rel="noreferrer noopener">
						<span className={styles.logo}>
							<img
								src={(() => {
									switch (provider) {
										case 'alltricks':
											return alltricks
										case 'amazon':
											return amazon
										case 'decathlon':
											return decathlon
										case 'ekosport':
											return ekosport
										case 'hardloop':
											return hardloop
										case 'irun':
											return irun
										case 'lecyclo':
											return lecyclo
										default:
											console.warn('no provider thumbnail', { name: provider })
											return null
									}
								})()}
								loading="lazy"
								decoding="async"
							/>
						</span>
						<span className={styles.name} style={{ fontWeight: index === 0 ? 700 : 400 }}>
							{(() => {
								switch (provider) {
									case 'alltricks':
										return 'Alltricks'
									case 'amazon':
										return 'Amazon'
									case 'decathlon':
										return 'Decathlon'
									case 'ekosport':
										return 'Ekosport'
									case 'hardloop':
										return 'Hardloop'
									case 'irun':
										return 'I-Run'
									case 'lecyclo':
										return 'Lecyclo'
									case 'lepape':
										return 'Lepape'
									default:
										console.warn('no provider name', { name: provider })
										return null
								}
							})()}
						</span>
						<span className={styles.value} style={{ fontWeight: index === 0 ? 700 : 400 }}>
							{metadata.currentPrice}€
						</span>
						<span className={styles.cta}>
							Voir&nbsp;
							<span>l'offre</span>
						</span>
					</a>
				)
			})
		}
	</div>
}